import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'next/router';
import { BackTop } from 'antd';
import css from './Sidebar.less';
import './Sidebar.css';

const TalSideBar = ({ router }) => {
  const ckeckRoute =
    router.route !== '/account/login' &&
    router.route !== '/downloadCenter' &&
    router.route !== '/account/register' &&
    router.route !== '/cooperation' &&
    router.route !== '/photo-search' &&
    router.route !== '/video_test' &&
    router.route !== '/video-Interpretation';

  const hasCooperation = ckeckRoute;
  const hasAppletQrCode =
    router.route !== '/account/login' &&
    router.route !== '/downloadCenter' &&
    router.route !== '/account/register' &&
    router.route !== '/cooperation' &&
    router.route !== '/photo-search' &&
    router.route !== '/video_test' &&
    router.route !== '/video-Interpretation';
  const hasEnterpriseWeChat =
    router.route !== '/account/login' &&
    router.route !== '/downloadCenter' &&
    router.route !== '/account/register' &&
    router.route !== '/cooperation' &&
    router.route !== '/photo-search' &&
    router.route !== '/video_test' &&
    router.route !== '/video-Interpretation';
  const [LookQrCode, setLookQrCode] = useState(false);
  const [nowDate, setNowDate] = useState(0);
  useEffect(() => {
    if (LookQrCode) {
      setNowDate(Number(new Date()));
    } else if (Number(new Date()) - nowDate > 1000 && nowDate !== 0) {
      window.$Aegis &&
        window.$Aegis.reportEvent({
          name: '查看小程序二维码',
          ext1: window.localStorage.getItem('AEGIS_ID')
        });
    }
  }, [LookQrCode]);
  return (
    <div className={css.sidebarBox} id="sidebarBox">
      {hasEnterpriseWeChat && (
        <div className={css.EnterpriseWeChat}>
          <img
            className={css.EnterpriseWeChatImg}
            src="../../static/navigator/EnterpriseWeChat.png"
            alt=""
          />
          <div className={css.EnterpriseWeChatHoverImgBox}>
            <div className={css.triangle} />
            <div className={css.text}>
              <p>扫描二维码</p>
              <p>添加企业微信</p>
              <p>了解更多信息</p>
            </div>
            <img src="../../static/navigator/EnterpriseWeChat.jpg" alt="" />
          </div>
          <span className={css.EnterpriseWeChatText}>企业微信</span>
        </div>
      )}
      {hasAppletQrCode && (
        <div
          className={css.qrCode}
          onMouseEnter={() => setLookQrCode(true)}
          onMouseLeave={() => setLookQrCode(false)}
        >
          <img
            className={css.qrCodeImg}
            src="https://openplantform.oss-cn-beijing.aliyuncs.com/index/erweima.png?OSSAccessKeyId=LTAI4FvBYgUM9wyFK4wa9nvy&Expires=5190737796&Signature=kfiwzfzJ5lSCbpgcMRLr05EgBWU%3D"
            alt=""
          />
          <span className={css.qrCodeText}>AI小程序</span>
          <div className={css.qrCodeHoverImgBox}>
            <div className={css.triangle} />
            <div className={css.text}>
              <p>扫描二维码</p>
              <p>体验AI能力</p>
            </div>
            <img src="../../static/navigator/SmallProgram.png" alt="" />
          </div>
        </div>
      )}
      {hasCooperation && (
        <div
          className={css.cooperation}
          onClick={() => window.open('/cooperation')}
        >
          <img
            className={css.cooperationImg}
            src="https://openplantform.oss-cn-beijing.aliyuncs.com/assets/images/cooperation.png?OSSAccessKeyId=LTAI4FvBYgUM9wyFK4wa9nvy&Expires=5186931040&Signature=JZjKBqv3Te86pG0c%2BpBvWKlLnNA%3D"
            alt=""
          />
          <span className={css.cooperationText}>合作咨询</span>
        </div>
      )}
      <BackTop>
        <div className="backtopImg" />
      </BackTop>
    </div>
  );
};

export default connect(null, null)(withRouter(TalSideBar));
